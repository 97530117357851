import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';

function VoteHistory() {
  const [voteHistory, setVoteHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchVoteHistory();
  }, []);

  const fetchVoteHistory = async () => {
    if (!auth.currentUser) return;

    setLoading(true);
    try {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists() && userDoc.data().voteHistory) {
        setVoteHistory(userDoc.data().voteHistory);
      }
    } catch (err) {
      console.error("Error fetching vote history:", err);
      setError("Failed to load vote history. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleChangeVote = async (index, newVote) => {
    if (!auth.currentUser) return;

    const updatedVoteHistory = [...voteHistory];
    updatedVoteHistory[index].vote = newVote;

    try {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userRef, { voteHistory: updatedVoteHistory });
      setVoteHistory(updatedVoteHistory);
    } catch (err) {
      console.error("Error updating vote:", err);
      alert("Failed to update vote. Please try again.");
    }
  };

  if (loading) return <div className="loading">Loading vote history...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="vote-history-container">
      <h2>Your Vote History</h2>
      {voteHistory.length === 0 ? (
        <p>You haven't voted on any bets yet.</p>
      ) : (
        <ul className="vote-history-list">
          {voteHistory.map((vote, index) => (
            <li key={index} className="vote-history-item">
              <div className="vote-info">
                <span className="vote-label">Bet ID:</span>
                <span className="vote-value">{vote.betId}</span>
              </div>
              <div className="vote-info">
                <span className="vote-label">Your vote:</span>
                <span className={`vote-value ${vote.vote.toLowerCase()}`}>{vote.vote}</span>
                <button onClick={() => handleChangeVote(index, vote.vote === 'yes' ? 'no' : 'yes')} className="change-vote-button">
                  Change to {vote.vote === 'yes' ? 'No' : 'Yes'}
                </button>
              </div>
              <div className="vote-info">
                <span className="vote-label">Voted on:</span>
                <span className="vote-value">{new Date(vote.timestamp.seconds * 1000).toLocaleDateString()}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default VoteHistory;