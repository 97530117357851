import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, doc, getDoc, Timestamp } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './PrivateBets.css';

function PrivateBets() {
  const [privateBets, setPrivateBets] = useState([]);
  const [isPremium, setIsPremium] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [bypassPayment, setBypassPayment] = useState(false);
  const [newBetTitle, setNewBetTitle] = useState('');
  const [newBetDescription, setNewBetDescription] = useState('');
  const [newBetAmount, setNewBetAmount] = useState('');
  const [newBetExpirationDate, setNewBetExpirationDate] = useState(new Date());
  const [newParticipant, setNewParticipant] = useState('');
  const [participants, setParticipants] = useState([]);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('created');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        const userData = userDoc.data();
        setIsPremium(userData.isPremium || false);
        setIsSuperAdmin(userData.email === 'wenitteapiou@gmail.com');
      }
    };

    const fetchPrivateBets = async () => {
      if (!auth.currentUser) return;

      const createdBetsQuery = query(
        collection(db, 'private-bets'),
        where('creatorId', '==', auth.currentUser.uid)
      );
      const invitedBetsQuery = query(
        collection(db, 'private-bets'),
        where('participants', 'array-contains', auth.currentUser.email)
      );

      const [createdBetsSnapshot, invitedBetsSnapshot] = await Promise.all([
        getDocs(createdBetsQuery),
        getDocs(invitedBetsQuery)
      ]);

      const createdBets = createdBetsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        type: 'created'
      }));

      const invitedBets = invitedBetsSnapshot.docs
        .filter(doc => doc.data().creatorId !== auth.currentUser.uid)
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          type: 'invited'
        }));

      setPrivateBets([...createdBets, ...invitedBets]);
    };

    fetchUserData();
    fetchPrivateBets();
  }, []);

  const handleBypassToggle = () => {
    setBypassPayment(!bypassPayment);
  };

  const handleAddParticipant = () => {
    if (newParticipant && !participants.includes(newParticipant)) {
      setParticipants([...participants, newParticipant]);
      setNewParticipant('');
    }
  };

  const handleRemoveParticipant = (participant) => {
    setParticipants(participants.filter(p => p !== participant));
  };

  const handleCreateBet = async (e) => {
    e.preventDefault();
    if (!isPremium && !bypassPayment) {
      setError('You need a premium subscription to create private bets.');
      return;
    }
  
    try {
      const newBet = {
        title: newBetTitle,
        description: newBetDescription,
        amount: parseFloat(newBetAmount),
        expirationDate: Timestamp.fromDate(newBetExpirationDate),
        creatorId: auth.currentUser.uid,
        participants: [auth.currentUser.email, ...participants],
        votes: { yes: 0, no: 0 },
        voters: { yes: [], no: [] },
        createdAt: Timestamp.now()
      };
  
      const docRef = await addDoc(collection(db, 'private-bets'), newBet);
      
      // Add the new bet to the state with its ID
      setPrivateBets(prevBets => [...prevBets, { id: docRef.id, ...newBet, type: 'created' }]);
  
      // Reset form fields
      setNewBetTitle('');
      setNewBetDescription('');
      setNewBetAmount('');
      setNewBetExpirationDate(new Date());
      setParticipants([]);
      setError('');
  
      // Close the modal
      setShowModal(false);
  
      console.log("New bet created with ID: ", docRef.id);
    } catch (error) {
      console.error("Error creating bet:", error);
      setError('Failed to create bet. Please try again.');
    }
  };

  const renderBetsList = (bets, isActive) => {
    const currentTime = new Date().getTime();
    const filteredBets = bets.filter(bet => {
      const expirationTime = bet.expirationDate.toDate().getTime();
      return (isActive ? expirationTime > currentTime : expirationTime <= currentTime) &&
             (activeTab === 'all' || bet.type === activeTab);
    });

    return filteredBets.map(bet => (
      <div key={bet.id} className="bet-card">
        <Link to={`/private-bet/${bet.id}`} className="bet-link">
          <h3 className="bet-title">{bet.title}</h3>
          <p className="bet-description">{bet.description}</p>
          <div className="bet-details">
            <p>Amount: ${bet.amount}</p>
            <p>Expires: {bet.expirationDate.toDate().toLocaleDateString()}</p>
            <p>Participants: {bet.participants.join(', ')}</p>
          </div>
          <div className="vote-container">
            <div className="vote-count yes-vote">Yes: {bet.votes.yes}</div>
            <div className="vote-count no-vote">No: {bet.votes.no}</div>
          </div>
        </Link>
      </div>
    ));
  };

  const renderCreateBetModal = () => (
    <div className="modal">
      <div className="modal-content">
        <h3 className="modal-title">Create New Bet</h3>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleCreateBet} className="bet-form">
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              id="title"
              type="text"
              value={newBetTitle}
              onChange={(e) => setNewBetTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              value={newBetDescription}
              onChange={(e) => setNewBetDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="amount">Amount ($):</label>
            <input
              id="amount"
              type="number"
              value={newBetAmount}
              onChange={(e) => setNewBetAmount(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="expirationDate">Expiration Date:</label>
            <DatePicker
              id="expirationDate"
              selected={newBetExpirationDate}
              onChange={(date) => setNewBetExpirationDate(date)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="newParticipant">Add Participant:</label>
            <div className="participant-input">
              <input
                id="newParticipant"
                type="email"
                value={newParticipant}
                onChange={(e) => setNewParticipant(e.target.value)}
                placeholder="Enter email"
              />
              <button type="button" onClick={handleAddParticipant} className="add-button">
                Add
              </button>
            </div>
          </div>
          {participants.length > 0 && (
            <div className="form-group">
              <label>Participants:</label>
              <ul className="participant-list">
                {participants.map((participant, index) => (
                  <li key={index} className="participant-item">
                    <span>{participant}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveParticipant(participant)}
                      className="remove-button"
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="form-actions">
            <button type="submit" className="submit-button">
              Create Bet
            </button>
            <button type="button" onClick={() => setShowModal(false)} className="cancel-button">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  if (!isPremium && !bypassPayment) {
    return (
      <div className="container">
        <h2 className="title">Private Bets</h2>
        <p className="premium-message">You need a premium subscription to access Private Bets.</p>
        <Link to="/settings" className="upgrade-link">Upgrade to Premium</Link>
        {isSuperAdmin && (
          <button onClick={handleBypassToggle} className="bypass-button">
            Bypass Payment (Admin Only)
          </button>
        )}
      </div>
    );
  }

  return (
    <div className="container private-bets">
      <h2 className="title">Private Bets</h2>
      {isSuperAdmin && bypassPayment && (
        <p className="admin-message">Admin mode: Payment bypass active</p>
      )}
      
      <div className="tab-container">
        <button onClick={() => setActiveTab('created')} className={`tab-button ${activeTab === 'created' ? 'active' : ''}`}>Created Bets</button>
        <button onClick={() => setActiveTab('invited')} className={`tab-button ${activeTab === 'invited' ? 'active' : ''}`}>Invited Bets</button>
        <button onClick={() => setActiveTab('all')} className={`tab-button ${activeTab === 'all' ? 'active' : ''}`}>All Bets</button>
      </div>

      <button onClick={() => setShowModal(true)} className="create-bet-button">Create New Bet</button>

      <h3 className="section-title">Active Bets</h3>
      {renderBetsList(privateBets, true)}

      <h3 className="section-title">Expired Bets</h3>
      {renderBetsList(privateBets, false)}

      {showModal && renderCreateBetModal()}
    </div>
  );
}

export default PrivateBets;