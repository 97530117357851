import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../firebase';

function UserProfile() {
  const { username } = useParams();
  const [user, setUser] = useState(null);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('posts');

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('username', '==', username));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setUser({ id: querySnapshot.docs[0].id, ...userData });
        } else {
          setError('User not found');
        }
      } catch (err) {
        console.error("Error fetching user:", err);
        setError("Failed to load user profile");
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [username]);

  useEffect(() => {
    if (activeTab === 'comments' && user) {
      fetchComments();
    }
  }, [activeTab, user]);

  const fetchComments = async () => {
    try {
      const commentsRef = collection(db, 'comments');
      const q = query(
        commentsRef,
        where('userId', '==', user.id),
        orderBy('createdAt', 'desc')
      );
      const querySnapshot = await getDocs(q);
      const fetchedComments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setComments(fetchedComments);
    } catch (error) {
      console.error("Error fetching comments:", error);
      setError("Failed to load user's comments");
    }
  };

  if (loading) return <div className="loading">Loading profile...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!user) return <div className="error">User not found</div>;

  const hasVotes = user.voteHistory && user.voteHistory.length > 0;

  return (
    <div className="user-profile">
      <div className="profile-header">
        <div className="profile-avatar">
          {user.photoURL ? (
            <img src={user.photoURL} alt={user.username} />
          ) : (
            <div className="avatar-placeholder">{user.username.charAt(0).toUpperCase()}</div>
          )}
        </div>
        <div className="profile-info">
          <h2 className="profile-username">{user.username}</h2>
          <p className="profile-karma">{user.karma || 0} karma</p>
          <p className="profile-cake-day">Cake day: {user.createdAt ? new Date(user.createdAt.seconds * 1000).toLocaleDateString() : 'Unknown'}</p>
        </div>
      </div>
      <div className="profile-tabs">
        <button
          className={`tab-button ${activeTab === 'posts' ? 'active' : ''}`}
          onClick={() => setActiveTab('posts')}
        >
          Posts
        </button>
        <button
          className={`tab-button ${activeTab === 'comments' ? 'active' : ''}`}
          onClick={() => setActiveTab('comments')}
        >
          Comments
        </button>
        <button
          className={`tab-button ${activeTab === 'about' ? 'active' : ''}`}
          onClick={() => setActiveTab('about')}
        >
          About
        </button>
      </div>
      <div className="profile-content">
        {activeTab === 'posts' && (
          <div className="posts-section">
            <h3>Posts</h3>
            <p>Post history functionality coming soon...</p>
          </div>
        )}
        {activeTab === 'comments' && (
          <div className="comments-section">
            <h3>Comments</h3>
            {comments.length > 0 ? (
              <ul className="comment-list">
                {comments.map(comment => (
                  <li key={comment.id} className="comment-item">
                    <p className="comment-text">{comment.text}</p>
                    <p className="comment-meta">
                      On bet: {comment.betId} | 
                      {new Date(comment.createdAt.seconds * 1000).toLocaleString()}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No comments yet.</p>
            )}
          </div>
        )}
        {activeTab === 'about' && (
          <div className="about-section">
            <h3>About</h3>
            <p>{user.bio || 'No bio provided'}</p>
            {hasVotes ? (
              <div className="vote-history">
                <h4>Vote History</h4>
                <p>{user.voteHistory.length} votes</p>
              </div>
            ) : (
              <p>Vote history is private</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default UserProfile;