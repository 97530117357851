import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, auth } from '../firebase';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const stripePromise = loadStripe('pk_live_51IB2OnFofG0T6L9bXptLm8q9uYQ9zWrzm8lPSCHlLs7m8bZvQEttXQNdEp3FjjdQqrsawJbL4nPoW08BxZL0U5nL00YKfwHTYY');

function Settings() {
  const [username, setUsername] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [bio, setBio] = useState('');
  const [email, setEmail] = useState('');
  const [showRank, setShowRank] = useState(true);
  const [isVoteHistoryPublic, setIsVoteHistoryPublic] = useState(true);
  const [photoURL, setPhotoURL] = useState('');
  const [newPhoto, setNewPhoto] = useState(null);
  const [message, setMessage] = useState('');
  const [isPremium, setIsPremium] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userRef = doc(db, 'users', auth.currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setUsername(userData.username || '');
          setDisplayName(userData.displayName || '');
          setBio(userData.bio || '');
          setEmail(userData.email || '');
          setShowRank(userData.showRank !== false);
          setIsVoteHistoryPublic(userData.isVoteHistoryPublic !== false);
          setPhotoURL(userData.photoURL || '');
          setIsPremium(userData.isPremium || false);
        }
        setLoading(false);
      }
    };
    fetchUserData();
  }, []);

  const handlePhotoChange = (e) => {
    if (e.target.files[0]) {
      setNewPhoto(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (auth.currentUser) {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      try {
        let updatedData = {
          username,
          displayName,
          bio,
          email,
          showRank,
          isVoteHistoryPublic
        };

        if (newPhoto) {
          const storage = getStorage();
          const photoRef = ref(storage, `userPhotos/${auth.currentUser.uid}`);
          await uploadBytes(photoRef, newPhoto);
          const photoURL = await getDownloadURL(photoRef);
          updatedData.photoURL = photoURL;
        }

        await updateDoc(userRef, updatedData);
        setMessage('Profile updated successfully!');
        if (newPhoto) {
          setPhotoURL(updatedData.photoURL);
          setNewPhoto(null);
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        setMessage('Failed to update profile. Please try again.');
      }
    }
  };

  const handleUpgradeClick = async () => {
    try {
      const { data } = await axios.post('https://us-central1-lets-bet-bab56.cloudfunctions.net/createStripeCheckoutSession', {
        subscribed: true,
        userId: auth.currentUser.uid
      });
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId
      });
      if (error) {
        setMessage(error.message);
      }
    } catch (error) {
      console.error('Error starting checkout:', error);
      setMessage('Failed to start checkout process. Please try again.');
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const { data } = await axios.post('https://us-central1-lets-bet-bab56.cloudfunctions.net/cancelSubscription', {
        userId: auth.currentUser.uid
      });
      setIsPremium(false);
      setMessage(data.message);
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setMessage('Failed to cancel subscription. Please try again.');
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="settings-container">
      <h2 className="settings-title">User Settings</h2>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit} className="settings-form">
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="displayName">Display Name:</label>
          <input
            type="text"
            id="displayName"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="bio">Bio:</label>
          <textarea
            id="bio"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="photo">Profile Photo:</label>
          {photoURL && <img src={photoURL} alt="Profile" className="profile-photo" />}
          <input
            type="file"
            id="photo"
            onChange={handlePhotoChange}
            accept="image/*"
          />
        </div>
        <div className="form-group checkbox">
          <label>
            <input
              type="checkbox"
              checked={showRank}
              onChange={(e) => setShowRank(e.target.checked)}
            />
            Show me on the leaderboard
          </label>
        </div>
        <div className="form-group checkbox">
          <label>
            <input
              type="checkbox"
              checked={isVoteHistoryPublic}
              onChange={(e) => setIsVoteHistoryPublic(e.target.checked)}
            />
            Make my vote history public
          </label>
        </div>
        <div className="form-group subscription">
          <h3>Premium Subscription</h3>
          {isPremium ? (
            <>
              <p>You are currently a premium subscriber.</p>
              <button type="button" onClick={handleCancelSubscription} className="cancel-subscription">Cancel Subscription</button>
            </>
          ) : (
            <>
              <p>Upgrade to Premium for $5/month to create Private Bets and access Private Leaderboards.</p>
              <button type="button" onClick={handleUpgradeClick} className="upgrade-button">Upgrade to Premium</button>
            </>
          )}
        </div>
        <button type="submit" className="submit-button">Save Changes</button>
      </form>
    </div>
  );
}

export default Settings;