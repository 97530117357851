import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useNavigate, useLocation } from 'react-router-dom';

function generateRandomUsername() {
  const adjectives = ['Happy', 'Sunny', 'Clever', 'Brave', 'Mighty', 'Wise', 'Kind', 'Swift'];
  const nouns = ['Lion', 'Eagle', 'Dolphin', 'Tiger', 'Wolf', 'Bear', 'Hawk', 'Fox'];
  const randomNumber = Math.floor(Math.random() * 1000);
  
  const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
  
  return `${randomAdjective}${randomNoun}${randomNumber}`;
}

function Auth() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState('');
  const [isStandaloneBrowser, setIsStandaloneBrowser] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkBrowserEnvironment();
    
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        createUserDocument(user).then(() => {
          const destination = location.state?.from || '/';
          navigate(destination);
        });
      }
    });

    return () => unsubscribe();
  }, [navigate, location]);

  const checkBrowserEnvironment = () => {
    const standalone = window.navigator.standalone;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);
    const android = /android/.test(userAgent);

    if (ios) {
      if (!standalone && !safari) {
        // iOS in-app browser
        setIsStandaloneBrowser(false);
      }
    } else if (android) {
      if (!userAgent.includes('chrome') || userAgent.includes('instagram')) {
        // Android in-app browser or Instagram
        setIsStandaloneBrowser(false);
      }
    }

    if (!isStandaloneBrowser) {
      attemptOpenDefaultBrowser();
    }
  };

  const attemptOpenDefaultBrowser = () => {
    const newWindow = window.open('https://specul8.app', '_system');
    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      // Fallback if unable to open default browser
      setError('Please open this site in a standalone browser. Visit specul8.app to sign up.');
    }
  };

  const createUserDocument = async (user) => {
    if (!user) return;
    
    const userRef = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      const randomUsername = generateRandomUsername();
      try {
        await setDoc(userRef, {
          email: user.email,
          username: randomUsername,
          displayName: user.displayName || user.email.split('@')[0],
          bio: '',
          voteHistory: [],
          isAdmin: false,
          showRank: true,
          score: 0
        });
        console.log("User document created successfully");
      } catch (error) {
        console.error("Error creating user document:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isStandaloneBrowser) {
      setError('Please open this site in a standalone browser. Visit specul8.app to sign up.');
      return;
    }
    setError('');
    try {
      let userCredential;
      if (isSignUp) {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
      } else {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      }
      await createUserDocument(userCredential.user);
      const destination = location.state?.from || '/';
      navigate(destination);
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    if (!isStandaloneBrowser) {
      setError('Please open this site in a standalone browser. Visit specul8.app to sign up.');
      return;
    }
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      await createUserDocument(result.user);
      const destination = location.state?.from || '/';
      navigate(destination);
    } catch (error) {
      console.error("Error with Google sign-in", error);
      setError(error.message);
    }
  };

  if (!isStandaloneBrowser) {
    return (
      <div className="auth-container">
        <div className="auth-form">
          <h2>Browser Not Supported</h2>
          <p>{error || 'Please open this site in a standalone browser. Visit specul8.app to sign up.'}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="auth-container">
      <div className="auth-form">
        <h2>{isSignUp ? 'Sign Up' : 'Sign In'}</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="auth-input"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="auth-input"
            required
          />
          <button type="submit" className="auth-button">
            {isSignUp ? 'Sign Up' : 'Sign In'}
          </button>
        </form>
        <button onClick={handleGoogleSignIn} className="auth-button google-button">
          Sign in with Google
        </button>
        <p className="auth-toggle">
          <button onClick={() => setIsSignUp(!isSignUp)} className="auth-toggle-button">
            {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
          </button>
        </p>
      </div>
    </div>
  );
}

export default Auth;