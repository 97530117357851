import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from './firebase';
import PrivateBetView from './components/PrivateBetView';

// Component imports
import Header from './components/Header';
import PublicFeed from './components/PublicFeed';
import PrivateBets from './components/PrivateBets';
import CreateBet from './components/CreateBet';
import Auth from './components/Auth';
import VoteHistory from './components/VoteHistory';
import Settings from './components/Settings';
import Leaderboard from './components/Leaderboard';
import UserProfile from './components/UserProfile';
import BetView from './components/BetView';

import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        setUser(user);
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          setIsAdmin(userDoc.exists() ? userDoc.data().isAdmin : false);
        } catch (error) {
          console.error("Error fetching user data:", error);
          setIsAdmin(false);
        }
      } else {
        setUser(null);
        setIsAdmin(false);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Header user={user} isAdmin={isAdmin} />
        <div className="container">
          <Routes>
            <Route path="/" element={<PublicFeed user={user} />} />
            <Route 
              path="/private-bets" 
              element={user ? <PrivateBets user={user} /> : <Navigate to="/auth" />} 
            />
            <Route 
              path="/create-bet" 
              element={isAdmin ? <CreateBet isAdmin={isAdmin} user={user} /> : <Navigate to="/" />} 
            />
            <Route path="/auth" element={<Auth />} />
            <Route 
              path="/vote-history" 
              element={user ? <VoteHistory user={user} /> : <Navigate to="/auth" />} 
            />
            <Route 
              path="/settings" 
              element={user ? <Settings user={user} /> : <Navigate to="/auth" />} 
            />
            
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/profile/:username" element={<UserProfile />} />
            <Route path="/private-bet/:betId" element={<PrivateBetView />} />
            <Route path="/bet/:betId" element={<BetView />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;