import { initializeApp } from 'firebase/app';
  import { getFirestore } from 'firebase/firestore';
  import { getAuth, GoogleAuthProvider } from 'firebase/auth';
  
  const firebaseConfig = {
    apiKey: "AIzaSyCAIwE2gXjmTae-8bFFOiGk9oKFOYqus-k",
    authDomain: "lets-bet-bab56.firebaseapp.com",
    projectId: "lets-bet-bab56",
    storageBucket: "lets-bet-bab56.appspot.com",
    messagingSenderId: "796828841160",
    appId: "1:796828841160:web:f3c9184aea52d66d5861fd",
    measurementId: "G-GFFDC84VY5"
  };
  
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);
  const googleProvider = new GoogleAuthProvider();
  
  export { auth, db, googleProvider  };