import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion, collection, addDoc, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { MessageSquare, Share2 } from 'react-feather';
import VoteModal from './VoteModal'; // Ensure this component exists

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    color: '#000',
  },
  betCard: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    padding: '20px',
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#000',
  },
  description: {
    fontSize: '16px',
    marginBottom: '15px',
    color: '#000',
  },
  expirationDate: {
    fontSize: '14px',
    color: '#333',
    marginBottom: '15px',
  },
  voteSection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  voteButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    position: 'relative',
  },
  voteArrow: {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
  },
  upvoteArrow: {
    borderBottom: '10px solid #ff4500',
  },
  downvoteArrow: {
    borderTop: '10px solid #ff4500',
  },
  voteCount: {
    margin: '0 10px',
    fontWeight: 'bold',
    color: '#000',
  },
  commentSection: {
    marginTop: '20px',
  },
  commentForm: {
    marginBottom: '20px',
  },
  commentInput: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    color: '#000',
  },
  submitButton: {
    padding: '10px 20px',
    backgroundColor: '#0079d3',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  comment: {
    backgroundColor: '#f8f9fa',
    borderRadius: '4px',
    padding: '10px',
    marginBottom: '10px',
    color: '#000',
  },
  commentMeta: {
    fontSize: '12px',
    color: '#666',
    marginTop: '5px',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
  yesNoButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  yesButton: {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  noButton: {
    padding: '10px 20px',
    backgroundColor: '#f44336',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

function BetView() {
  const { betId } = useParams();
  const [bet, setBet] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const [showVoteModal, setShowVoteModal] = useState(false);
  const [selectedVote, setSelectedVote] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
    });

    fetchBet();
    fetchComments();

    return () => unsubscribe();
  }, [betId]);

  const fetchBet = async () => {
    try {
      const betDoc = await getDoc(doc(db, 'publicBets', betId));
      if (betDoc.exists()) {
        const betData = betDoc.data();
        if (!Array.isArray(betData.upvotes)) {
          const initialUpvotes = Math.floor(Math.random() * (200 - 50 + 1)) + 50;
          await updateDoc(doc(db, 'publicBets', betId), { upvotes: new Array(initialUpvotes).fill(null) });
          betData.upvotes = new Array(initialUpvotes).fill(null);
        }
        if (!Array.isArray(betData.downvotes)) {
          betData.downvotes = [];
        }
        setBet({ id: betDoc.id, ...betData });
      } else {
        setError('Bet not found');
      }
    } catch (error) {
      console.error("Error fetching bet:", error);
      setError('Failed to load bet');
    }
  };

  const fetchUsernames = async (comments) => {
    const updatedComments = await Promise.all(comments.map(async comment => {
      const userDoc = await getDoc(doc(db, 'users', comment.userId));
      if (userDoc.exists()) {
        return { ...comment, username: userDoc.data().username || 'Anonymous' };
      } else {
        return { ...comment, username: 'Anonymous' };
      }
    }));
    setComments(updatedComments);
  };

  const fetchComments = async () => {
    try {
      const q = query(
        collection(db, 'comments'), 
        where('betId', '==', betId),
        orderBy('createdAt', 'desc')
      );
      const querySnapshot = await getDocs(q);
      const fetchedComments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      fetchUsernames(fetchedComments);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleVote = async (voteType) => {
    if (!user) {
      navigate('/auth', { state: { from: `/bet/${betId}` } });
      return;
    }
    try {
      const betRef = doc(db, 'publicBets', betId);
      let newUpvotes = [...bet.upvotes];
      let newDownvotes = [...bet.downvotes];

      if (voteType === 'upvote') {
        if (newUpvotes.includes(user.uid)) {
          newUpvotes = newUpvotes.filter(id => id !== user.uid);
        } else {
          newUpvotes.push(user.uid);
          newDownvotes = newDownvotes.filter(id => id !== user.uid);
        }
      } else {
        if (newDownvotes.includes(user.uid)) {
          newDownvotes = newDownvotes.filter(id => id !== user.uid);
        } else {
          newDownvotes.push(user.uid);
          newUpvotes = newUpvotes.filter(id => id !== user.uid);
        }
      }

      await updateDoc(betRef, {
        upvotes: newUpvotes,
        downvotes: newDownvotes
      });
      setBet({...bet, upvotes: newUpvotes, downvotes: newDownvotes});
    } catch (error) {
      console.error("Error voting:", error);
      setError('Failed to vote. Please try again.');
    }
  };

  const handleYesNoVote = (vote) => {
    if (!user) {
      navigate('/auth', { state: { from: `/bet/${betId}` } });
      return;
    }
    setSelectedVote(vote);
    setShowVoteModal(true);
  };

  const confirmVote = async () => {
    try {
      const betRef = doc(db, 'publicBets', betId);
      await updateDoc(betRef, {
        [`votes.${selectedVote}`]: arrayUnion(user.uid)
      });
      setShowVoteModal(false);
      // Optionally, you can fetch the bet data again to update the local state
      fetchBet();
    } catch (error) {
      console.error("Error confirming vote:", error);
      setError('Failed to confirm vote. Please try again.');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      navigate('/auth', { state: { from: `/bet/${betId}` } });
      return;
    }
    if (!newComment.trim()) return;

    try {
      await addDoc(collection(db, 'comments'), {
        betId: betId,
        userId: user.uid,
        text: newComment,
        createdAt: new Date()
      });
      setNewComment('');
      fetchComments();
    } catch (error) {
      console.error("Error adding comment:", error);
      setError('Failed to add comment. Please try again.');
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: bet.title,
        text: bet.description,
        url: window.location.href
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing:', error));
    } else {
      navigator.clipboard.writeText(window.location.href)
        .then(() => alert('Link copied to clipboard!'))
        .catch(err => console.error('Failed to copy: ', err));
    }
  };

  if (error) {
    return <div style={styles.container}><p style={styles.error}>{error}</p></div>;
  }

  if (!bet) {
    return <div style={styles.container}>Loading bet...</div>;
  }

  return (
    <div style={styles.container}>
      <div style={styles.betCard}>
        <h2 style={styles.title}>{bet.title}</h2>
        <p style={styles.description}>{bet.description}</p>
        {bet.expirationDate && (
          <p style={styles.expirationDate}>Expires: {new Date(bet.expirationDate.seconds * 1000).toLocaleDateString()}</p>
        )}
        <div style={styles.voteSection}>
          <button onClick={() => handleVote('upvote')} style={styles.voteButton}>
            <div style={{...styles.voteArrow, ...styles.upvoteArrow, borderBottomColor: bet.upvotes.includes(user?.uid) ? '#ff4500' : '#000'}} />
          </button>
          <span style={styles.voteCount}>{bet.upvotes.length - bet.downvotes.length}</span>
          <button onClick={() => handleVote('downvote')} style={styles.voteButton}>
            <div style={{...styles.voteArrow, ...styles.downvoteArrow, borderTopColor: bet.downvotes.includes(user?.uid) ? '#7193ff' : '#000'}} />
          </button>
        </div>
        <div style={styles.yesNoButtons}>
          <button onClick={() => handleYesNoVote('yes')} style={styles.yesButton}>Yes</button>
          <button onClick={() => handleYesNoVote('no')} style={styles.noButton}>No</button>
        </div>
        <button onClick={handleShare} style={styles.submitButton}>
          <Share2 /> Share
        </button>
        <div style={styles.commentSection}>
          <h3>Comments</h3>
          <form onSubmit={handleCommentSubmit} style={styles.commentForm}>
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Write a comment..."
              style={styles.commentInput}
            />
            <button type="submit" style={styles.submitButton}>Submit</button>
          </form>
          {comments.map(comment => (
            <div key={comment.id} style={styles.comment}>
              <p>{comment.text}</p>
              <p style={styles.commentMeta}>By {comment.username} on {new Date(comment.createdAt.seconds * 1000).toLocaleString()}</p>
            </div>
          ))}
        </div>
      </div>
      {showVoteModal && (
        <VoteModal
          bet={bet}
          selectedVote={selectedVote}
          onConfirm={confirmVote}
          onClose={() => setShowVoteModal(false)}
        />
      )}
    </div>
  );
}

export default BetView;