import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import './Leaderboard.css';

function generateRandomUsername() {
  const adjectives = ['Happy', 'Sunny', 'Clever', 'Brave', 'Mighty', 'Wise', 'Kind', 'Swift'];
  const nouns = ['Lion', 'Eagle', 'Dolphin', 'Tiger', 'Wolf', 'Bear', 'Hawk', 'Fox'];
  const randomNumber = Math.floor(Math.random() * 1000);
  const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
  return `${randomAdjective}${randomNoun}${randomNumber}`;
}

function Leaderboard() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'users'));
        const fetchedUsers = await Promise.all(querySnapshot.docs.map(async (docSnapshot) => {
          let userData = docSnapshot.data();
          let updated = false;
          if (!userData.username) {
            userData.username = generateRandomUsername();
            updated = true;
          }
          if (userData.score === undefined) {
            userData.score = 0;
            updated = true;
          }
          if (updated) {
            await updateDoc(doc(db, 'users', docSnapshot.id), userData);
          }
          return {
            id: docSnapshot.id,
            ...userData
          };
        }));
        fetchedUsers.sort((a, b) => b.score - a.score);
        console.log('Fetched users:', fetchedUsers); // Debug log
        setUsers(fetchedUsers);
      } catch (err) {
        console.error("Error fetching users:", err);
        setError("Failed to load leaderboard. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const handleViewProfile = (username) => {
    navigate(`/profile/${username}`);
  };

  if (loading) return <div className="loading">Loading leaderboard...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="leaderboard-container">
      <h2 className="leaderboard-title">Leaderboard</h2>
      {users.length === 0 ? (
        <p className="no-users">No users to display on the leaderboard.</p>
      ) : (
        <div className="leaderboard-table-container">
          <table className="leaderboard-table">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Username</th>
                <th>Vote History</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user.id} className="leaderboard-item">
                  <td className="rank">{index + 1}</td>
                  <td className="username">{user.username}</td>
                  <td className="vote-history">
                    {user.voteHistory && user.voteHistory.length > 0
                      ? `${user.voteHistory.length} votes`
                      : 'private'}
                  </td>
                  <td className="action">
                    <button onClick={() => handleViewProfile(user.username)} className="view-profile-btn">
                      View Profile
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Leaderboard;