import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

function VoteModal({ bet, onConfirm, onClose }) {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmedVote, setConfirmedVote] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/auth');
    }
  }, [navigate]);

  const handleVote = (vote) => {
    if (!auth.currentUser) {
      navigate('/auth');
      return;
    }
    onConfirm(vote);
    setConfirmedVote(vote);
    setIsConfirmed(true);
  };

  if (!auth.currentUser) {
    return null; // Don't render anything if not logged in
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {!isConfirmed ? (
          <>
            <h2>Confirm Your Vote</h2>
            <p>{bet.title}</p>
            <div className="modal-buttons">
              <button className="modal-button confirm-yes" onClick={() => handleVote('yes')}>Yes</button>
              <button className="modal-button confirm-no" onClick={() => handleVote('no')}>No</button>
            </div>
            <button className="modal-button cancel" onClick={onClose}>Cancel</button>
          </>
        ) : (
          <>
            <h2>Vote Confirmed</h2>
            <p>You voted: <strong>{confirmedVote}</strong> on "{bet.title}"</p>
            <p>You can view and change your vote in the Vote History page.</p>
            <div className="modal-buttons">
              <Link to="/vote-history" className="modal-button">Go to Vote History</Link>
              <button className="modal-button" onClick={onClose}>Close</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default VoteModal;