import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function CreateBet({ user, isAdmin }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isPublic && !isAdmin) {
      alert('You must be an admin to create public bets');
      return;
    }

    try {
      const betData = {
        title,
        description,
        isPublic,
        votes: { yes: 0, no: 0 },
        createdAt: new Date(),
        createdBy: user.uid,
        expirationDate: expirationDate ? expirationDate : null
      };

      const collectionName = isPublic ? 'publicBets' : 'privateBets';
      await addDoc(collection(db, collectionName), betData);
      
      setTitle('');
      setDescription('');
      setExpirationDate(null);
      alert('Bet created successfully');
    } catch (error) {
      console.error('Error creating bet:', error);
      alert('Error creating bet');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="auth-form">
      <h2>Create a New Bet</h2>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Bet Title"
        className="form-input"
        required
      />
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Bet Description"
        className="form-input"
        required
      />
      <div>
        <label>
          <input
            type="checkbox"
            checked={isPublic}
            onChange={(e) => setIsPublic(e.target.checked)}
          />
          Public Bet (Admin only)
        </label>
      </div>
      <div>
        <label>Expiration Date (optional):</label>
        <DatePicker
          selected={expirationDate}
          onChange={(date) => setExpirationDate(date)}
          className="form-input"
          placeholderText="No expiration"
        />
      </div>
      <button type="submit" className="form-button">Create Bet</button>
    </form>
  );
}

export default CreateBet;