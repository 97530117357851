import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { Link } from 'react-router-dom';

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  betCard: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '15px',
    marginBottom: '15px',
  },
  voteButton: {
    padding: '5px 10px',
    marginRight: '10px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
};

function PrivateBetView() {
  const { betId } = useParams();
  const [bet, setBet] = useState(null);
  const [error, setError] = useState('');
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    const fetchBetAndUserData = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        const userData = userDoc.data();
        setIsPremium(userData.isPremium || false);

        const betDoc = await getDoc(doc(db, 'private-bets', betId));
        if (betDoc.exists()) {
          setBet({ id: betDoc.id, ...betDoc.data() });
        } else {
          setError('Bet not found');
        }
      }
    };

    fetchBetAndUserData();
  }, [betId]);

  const handleVote = async (vote) => {
    if (!isPremium) {
      setError('You need a premium subscription to vote on bets.');
      return;
    }

    try {
      const betRef = doc(db, 'private-bets', betId);
      const betDoc = await getDoc(betRef);
      const betData = betDoc.data();

      if (betData.voters.yes.includes(auth.currentUser.email) || betData.voters.no.includes(auth.currentUser.email)) {
        setError('You have already voted on this bet.');
        return;
      }

      await updateDoc(betRef, {
        [`votes.${vote}`]: betData.votes[vote] + 1,
        [`voters.${vote}`]: arrayUnion(auth.currentUser.email)
      });

      // Refresh bet data
      const updatedBetDoc = await getDoc(betRef);
      setBet({ id: updatedBetDoc.id, ...updatedBetDoc.data() });
    } catch (error) {
      console.error("Error voting:", error);
      setError('Failed to submit vote. Please try again.');
    }
  };

  if (!bet) {
    return <div style={styles.container}>{error || 'Loading...'}</div>;
  }

  const currentTime = new Date().getTime();
  const expirationTime = bet.expirationDate.toDate().getTime();
  const isActive = expirationTime > currentTime;

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Private Bet</h2>
      <div style={styles.betCard}>
        <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>{bet.title}</h3>
        <p style={{ marginBottom: '10px' }}>{bet.description}</p>
        <p style={{ fontSize: '14px', marginBottom: '5px' }}>Amount: ${bet.amount}</p>
        <p style={{ fontSize: '14px', marginBottom: '5px' }}>Expires: {bet.expirationDate.toDate().toLocaleDateString()}</p>
        <p style={{ fontSize: '14px', marginBottom: '10px' }}>Participants: {bet.participants.join(', ')}</p>
        <div>
          <span style={{ marginRight: '15px' }}>Yes: {bet.votes.yes}</span>
          <span>No: {bet.votes.no}</span>
        </div>
        {isActive && (
          <div style={{ marginTop: '10px' }}>
            <button 
              onClick={() => handleVote('yes')} 
              style={{ ...styles.voteButton, backgroundColor: 'green' }}
              disabled={!isPremium}
            >
              Vote Yes
            </button>
            <button 
              onClick={() => handleVote('no')} 
              style={{ ...styles.voteButton, backgroundColor: 'red' }}
              disabled={!isPremium}
            >
              Vote No
            </button>
          </div>
        )}
        {!isPremium && (
          <p style={{ marginTop: '10px', color: 'blue' }}>
            <Link to="/settings">Upgrade to Premium</Link> to vote on this bet.
          </p>
        )}
        {error && <p style={styles.error}>{error}</p>}
      </div>
    </div>
  );
}

export default PrivateBetView;