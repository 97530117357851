import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';

function Header({ user, isAdmin }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo">Specul8</Link>
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰
        </button>
        <nav className={`nav-links ${isOpen ? 'open' : ''}`}>
          <Link to="/" className="nav-link" onClick={toggleMenu}>Public Feed</Link>
          <Link to="/leaderboard" className="nav-link" onClick={toggleMenu}>Leaderboard</Link>
          {user && (
            <>
              <Link to="/private-bets" className="nav-link" onClick={toggleMenu}>Private Bets</Link>
              <Link to="/vote-history" className="nav-link" onClick={toggleMenu}>Vote History</Link>
              <Link to="/settings" className="nav-link" onClick={toggleMenu}>Settings</Link>
            </>
          )}
          {isAdmin && (
            <Link to="/create-bet" className="nav-link" onClick={toggleMenu}>Create Bet</Link>
          )}
          {user ? (
            <div className="user-menu">
              <span className="username">{user.displayName || user.email}</span>
              <button onClick={handleSignOut} className="sign-out-button">Sign Out</button>
            </div>
          ) : (
            <Link to="/auth" className="nav-link" onClick={toggleMenu}>Sign In</Link>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Header;